import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { Row, Col, Tab, Tabs, Modal, Alert, Button } from 'react-bootstrap';
import * as FDS from '@arctravel/react-fds/lib';
import { getOts, getOtsLength } from '../../okta/ls';
import { useDispatch, useSelector } from 'react-redux';
import { TitlePanel } from './TitlePanel';
import { DownloadPanel } from './DownloadPanel';
import { templates, processResponse } from '../utils';
import { BI } from '../bi';
import { ARCCIDataStatus } from '../otherReports/arcci/ARCCIDataStatus';
import { CheckReportReady } from './CheckReportReady';
import { FDSInfiniteTable } from '../utils/FDSInfiniteTable';

//import { cdbdata } from '../utils/SampleData/cdb';

import './Resultspanel.scss';

declare const window: any;

interface ResultsProps {
    params: any,
    onLoadRun: boolean,
    staticFilter: boolean
}

const longRunningThreshold = 45

export const ResultsPanel = (props: ResultsProps) => {
    const longRunMsg = "Report generating is taking a bit longer, just like taxing on the tarmac. You can wait or check your report history for the final report and continue working in the meantime.";
    const dispatch = useDispatch();
    const history = useHistory();
    // const notificationRef = useSelector((store: any) => store.notificationRef);
    const reportIpRef = useSelector((state: any) => state.reportIpRef);
    const customersRef = useSelector((state: any) => state.customersRef);
    const [tab, setTab] = useState("tab1");
    const [user] = useState(getOtsLength() > 0 ? getOts()?.idToken?.name : "");
    const [repName, setRepName] = useState("");
    const [data, setData] = useState({});
    const [isToomanyCols, setToomanyCols] = useState(false);
    const [showLongRunning, setShowLongRunning] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [showVisualizations, setShowVisualizations] = useState(false);
    const isReportReady = useRef(false);
    const timer: any = useRef();
    const time = useRef(0);
    const repeatTimer: any = useRef();

    const demoReports: any = {
        // "OND_DEMAND": cdbdata,
        //"CARRIER_DASHBOARD": cdbdata,
    }

    const defaultConfig = useMemo(() => {
        return {
            apiUrl: window.ENV.reportsDomain,
            token: localStorage.getItem("bearer") + '',
            data: {},
            pageRecordCount: 250,
            showTooltips: true,
            compacted: true,
            columns: [],
            borderedHeader: true,
            header: {},
            headerOnly: false,
            sort: true,
            totalLine: [],
            pivote: {
                rows: {
                    count: 0,
                    color: "info"
                },
                cols: {
                    count: 0,
                    color: "warning"
                }
            }
        }
    }, []);

    const [config, setConfig] = useState(defaultConfig);

    const tableChanged = (response: any) => {
        console.log("tableChanged", response);
        if (response?.status) {
            dispatch({ type: "LOADING", data: "" });
        }
        expandResults(null);
    }

    const showResults = () => {
        return (
            props.params === "ARCCIDS" ?
                (<ARCCIDataStatus data={data} />) :
                props.onLoadRun ?
                    (
                        data && <BI
                            data={JSON.parse(JSON.stringify(
                                Object.keys(demoReports).indexOf(props.params) >= 0 ?
                                    demoReports[props.params] :
                                    data
                            ))}
                            template={templates[props.params]}
                            showReport={showVisualizations}
                        />
                    ) :
                    templates[props.params].chartsLists?.length > 0 ?
                        <Tabs className="nav-scoped" defaultActiveKey={"tab1"} transition={false}
                            onSelect={(key: any) => {
                                setTab(key);
                                if (key === "tab2" && Object.keys(data).length <= 0) {
                                    handshake({ sort_index: -1, sort_type: "ASC" }, true, repName, false, false);
                                }
                            }}>
                            <Tab eventKey="tab1" title="Detailed Data">
                                <div className="resultsWrap" style={{ marginTop: "-16px" }}>
                                    <FDSInfiniteTable config={config} onChange={tableChanged} onSort={sortTable} />
                                </div>
                            </Tab>
                            <Tab id="Visualizations" eventKey="tab2" title="Visualizations">
                                {tab === "tab2" ? <BI data={JSON.parse(JSON.stringify(data))} template={templates[props.params]} showReport={showVisualizations} /> : null}
                            </Tab>
                        </Tabs> :
                        <div className="resultsWrap" style={{ marginTop: "-16px" }}>
                            {isToomanyCols ?
                                <Alert data-testid="table-main-no-data" className="mt-3" variant="info">
                                    <div className="icon"><i className="fds-glyphs-info2"></i></div>
                                    <div className="body">Too many columns to render for this search criteria. Download the report
                                        <Button className='p-0 ms-2 me-1' variant="link"
                                            onClick={() => history.push('/hub/' + repName)} style={{ marginTop: "-3px" }}>
                                            <b>here</b>
                                        </Button> to view the results.
                                    </div>
                                </Alert> :
                                <FDSInfiniteTable config={config} onChange={tableChanged} onSort={sortTable} />
                            }
                        </div>
        );
    }

    const visualize = (reportName: string) => {
        dispatch({ type: "LOADING", data: "Loading report" });
        axios.get(window.ENV.exportDomain.replace("export?", "viewInUI?") + reportName, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("bearer"),
                'Content-Type': 'application/json'
            }
        }).then((response: any) => {
            const data = processResponse(response);
            dispatch({ type: "LOADING", data: "Downloading data" });
            setData(data[reportName]);
            setTimeout(() => {
                dispatch({ type: "LOADING", data: "" });
                setShowLongRunning(false);
                setShowVisualizations(true);
            }, 200);
            if (data?.message?.length > 0) {
                if (Object.keys(demoReports).indexOf(props.params) >= 0) {
                    dispatch({ type: "STATUS", data: { type: "DANGER", title: "Sample Data", message: "You are viewing sample data." } });
                } else {
                    dispatch({ type: "STATUS", data: { type: "DANGER", title: "Report Not Available", message: "Report is not available to visualize." } });
                }

                setData(Object.keys(demoReports).indexOf(props.params) >= 0 ? demoReports[props.params] : {});
            }
        }).catch((error: AxiosError) => {
            setData({});
            dispatch({ type: "LOADING", data: "" });
            if (error.response?.status === 502) {
                dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `Data size is too large to visualize.` } });
            } else {
                dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${error.message})` } });
            }
        });
    }

    const getARCCIDS = (reportName: string) => {
        dispatch({ type: "LOADING", data: "Loading report" });
        axios.post(window.ENV.reportsDomain, { reportName: reportName, alldata: true }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("bearer"),
                'Content-Type': 'application/json'
            }
        }).then((response: any) => {
            const data = processResponse(response);
            setRepName(data.reportName || "");
            setData(data.results);
            setTimeout(() => {
                dispatch({ type: "LOADING", data: "" });
                setShowLongRunning(false);
                setShowVisualizations(true);
            }, 200);
        }).catch((error: AxiosError) => {
            setData({});
            dispatch({ type: "LOADING", data: "" });
            if (error.response?.status === 502) {
                dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `Data size is too large to visualize.` } });
            } else {
                dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${error.message})` } });
            }
        });
    }

    const triggerTimer = useCallback(() => {
        ["DEV", "QA"].indexOf(window.ENV.type) >= 0 && console.log("AAAA:1:", time.current, isReportReady.current);

        if (isReportReady.current) {
            clearInterval(timer.current);
            setShowLongRunning(false);
            dispatch({ type: "LOADING", data: "" });
        } else if ((localStorage.getItem("reportRequestId") || "").length > 0) {
            ["DEV", "QA"].indexOf(window.ENV.type) >= 0 && console.log("AAAA:3:")
            ++time.current;
        }

        if (time.current >= (longRunningThreshold - 1)) {
            console.log("AAAA:4:");
            setShowLongRunning(true);
            time.current = 0;
        }
    }, [dispatch, isReportReady, time]);

    // Handshake Request
    const handshake = useCallback((sortObj: any, isGraph: boolean, reportName: string, isInit: boolean, noAsync?: boolean) => {
        time.current = 0;
        isReportReady.current = false;
        setShowReport(!noAsync);
        setShowLongRunning(false);
        setShowVisualizations(false);
        console.log("EVENT---:HS:", reportName);
        dispatch({ type: "LOADING", data: `Hang tight, your report is generating. May take up to ${longRunningThreshold} seconds.` });

        if (isInit) {
            setData({});
        }

        const repIp = JSON.parse(JSON.stringify(reportIpRef));

        delete repIp.executionType;

        if (props.params === "ARCCIDS") {
            repIp.responseType = "zip";
        }

        if (!isGraph) {
            delete repIp.template.graphs;
        }

        if (reportName?.length > 0) {
            repIp.reportName = reportName;
        }

        if (Object.keys(repIp)?.length <= 0) {
            return;
        }

        const d = new Date().getTime().toString();
        const rrid = "1" + d.substring(d.length - 6, d.length);
        localStorage.setItem("reportRequestId", rrid);

        const headers: any = {
            'Authorization': 'Bearer ' + localStorage.getItem("bearer"),
            'Content-Type': 'application/json'
        };

        const payload: any = {
            /*pageIndex: -1,*/
            ...sortObj
        };

        if (!noAsync) {
            headers['invocationtype'] = 'Event';
            payload['requestId'] = parseInt(rrid);
        }

        axios.post(window.ENV.reportsDomain, { ...repIp, ...(templates[props.params].disablePagination ? {} : { ...payload }) }, {
            headers: headers
        }).then((response: any) => {
            const data = processResponse(response);

            if (data?.message?.toLowerCase()?.indexOf("600") > 0) {
                dispatch({ type: "STATUS", data: { type: "INFO", title: "Notification", message: "The number of columns which would be generated by this query exceeds 600. Try moving some fields to rows, and avoid using fields like travel date and purchase date as columns" } });
                window.document.querySelectorAll(".minBtn")[0]?.click();
                return;
            }

            if (data?.message === "Internal Server Error") {
                dispatch({ type: "STATUS", data: { type: "DANGER", title: "Request Failed", message: "Request Failed. Contact system administrator." } });
                window.document.querySelectorAll(".minBtn")[0]?.click();
                setShowLongRunning(false);
                dispatch({ type: "LOADING", data: "" });
                return;
            }

            timer.current = setInterval(() => {
                triggerTimer();
            }, 1000);
        }).catch((error: AxiosError) => {
            setConfig((prev: any) => {
                return { ...prev, data: {} }
            });
            setData({});
            dispatch({ type: "REPORT_IP", data: {} });
            dispatch({ type: "LOADING", data: "" });

            if (error.response?.status === 504) { // Timeout
                dispatch({ type: "STATUS", data: { type: "INFO", title: "Notification", message: longRunMsg } });
            } else if (error.response?.status === 507) { // Column Limit
                dispatch({ type: "STATUS", data: { type: "INFO", title: "Notification", message: "The number of columns which would be generated by this query exceeds 600. Try moving some fields to rows, and avoid using fields like travel date and purchase date as columns" } });
            } else {
                // TODO
                //dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `We’re sorry but your query exceeds the carry-on bag size limit. Please change your parameters.` } });
                dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${error.message})` } });
            }
            window.document.getElementById("reportPageLeftToggleRef")?.classList?.remove("min");
        });
    }, [reportIpRef, props.params, isReportReady, triggerTimer, dispatch]);

    const sortTable = (sortObj: any) => {
        console.log("SSSSSSS:", sortObj);
        handshake({ ...sortObj, reportName: repName }, false, "", false);
    }

    const expandResults = (ev: any) => {
        const expanded = "expanded";
        if (ev) {
            ev.target?.classList.toggle(expanded);
            document.getElementById("resultsWrapBackdrop")?.classList.toggle("show");
            document.getElementById("resultsWrap")?.classList.toggle(expanded);
            document.getElementsByTagName("body")[0]?.classList.toggle("overflow-hidden");
        }
    }

    const reportReady = (body: any, reportType: string, statusCode: any) => {
        console.log("AAAA:5.0:", body);
        const rrid = localStorage.getItem("reportRequestId");

        if (body.reportName?.length > 0 && body?.requestId?.toString() === rrid) {
            console.log("AAAA:6:CLEAR:", repName, ":", body.reportName)
            //clearInterval(timer.current);
            isReportReady.current = true;
            localStorage.removeItem("reportRequestId");

            if (reportIpRef.executionType === "D") {
                dispatch({ type: "LOADING", data: "File creation in progress." });
            }

            if (body.message?.toLowerCase()?.indexOf("600") > 0 || statusCode === 507) {
                dispatch({ type: "STATUS", data: { type: "INFO", title: "Notification", message: "The number of columns which would be generated by this query exceeds 600. Try moving some fields to rows, and avoid using fields like travel date and purchase date as columns" } });
                window.document.querySelectorAll(".minBtn")[0]?.click();
                return;
            }

            if (body?.reportOutputFormat && body.reportOutputFormat === "CSV") {
                dispatch({ type: "STATUS", data: { type: "INFO", title: "Notification", message: "Your report output exceeds 1M records and will be available to download as a .csv file." } });
                window.document.querySelectorAll(".minBtn")[0]?.click();
                return;
            }

            if (body.message?.indexOf("Request Failed") >= 0 || statusCode !== 200) {
                dispatch({ type: "STATUS", data: { type: "DANGER", title: "Request Failed", message: "Request Failed. Contact system administrator." } });
                window.document.querySelectorAll(".minBtn")[0]?.click();
                return;
            }

            if (templates[reportType].onLoadRun || body.reportName.toLowerCase().indexOf("_charts") > 0 || reportType === "ARCCIDS") {
                if (templates[reportType].onLoadRun) {
                    setRepName(body?.reportName);
                }
                if (reportType === "ARCCIDS") {
                    getARCCIDS(body.reportName);
                } else {
                    visualize(body.reportName);
                }
                return;
            }

            if (repName.length === 0) {
                setRepName(body?.reportName);
                setToomanyCols(body?.title?.toLowerCase()?.indexOf("column limit exceeded") > 0);

                const newConfig: any = {
                    token: localStorage.getItem("bearer") + '',
                    data: {
                        reportName: body?.reportName
                    },
                    totalLinePosition: "BOTTOM"
                };

                if (reportIpRef?.template?.rows?.length + reportIpRef?.template?.cols?.length > 0) {
                    newConfig.pivote = {
                        rows: {
                            count: reportIpRef?.template?.rows.length,
                            color: "info"
                        },
                        cols: {
                            count: reportIpRef?.template?.cols?.length,
                            color: "warning"
                        }
                    }
                }

                setConfig((prev: any) => {
                    return {
                        ...prev,
                        ...newConfig
                    }
                });
            }
        }
    }

    useEffect(() => {
        setConfig(defaultConfig);
        if (reportIpRef.reportType === props.params) {
            setConfig((prev: any) => {
                return {
                    ...prev,
                    token: localStorage.getItem("bearer") + '',
                    data: {}
                };
            });
            setRepName("");
            setData({});
            setTab("tab1");

            if (reportIpRef?.viewAs?.org === customersRef?.viewAs?.org) {
                const diffT = new Date().getTime() - repeatTimer.current;
                repeatTimer.current = new Date().getTime();
                if (Number.isNaN(diffT) || diffT > 10000) {
                    handshake({ sort_index: -1, sort_type: "ASC" }, templates[props.params].onLoadRun, "", true);
                }
            }
        }
        return () => {
            setData({});
            setRepName("");
            setTab("tab1");
            setShowReport(false);
            setShowVisualizations(false);
            setShowLongRunning(false);
            setConfig(defaultConfig);
            isReportReady.current = false
            time.current = 0;
            clearInterval(timer.current);
        }
    }, [handshake, reportIpRef, props.params, props.onLoadRun, defaultConfig, customersRef?.viewAs?.org]);

    return (
        <React.Fragment>
            <>
                <div id="resultsWrapBackdrop" className='fade modal-backdrop'></div>
                <DownloadPanel reportIpRef={reportIpRef} />
                {(showReport && props.params === reportIpRef.reportType) || props.onLoadRun ?
                    <Col id="resultsWrap" className={`${props.onLoadRun ? 'darkbg spacing' : ''}`} style={{ overflow: 'auto' }}>
                        {((config.data && Object.keys(config.data)?.length > 0) || props.onLoadRun || Object.keys(data).length > 0) && Object.keys(reportIpRef).length > 1 && reportIpRef.executionType !== "D" ?
                            <div>
                                <Row className="pt-3">
                                    {repName?.length > 0 ?
                                        <>
                                            <Col>
                                                {
                                                    !templates[props.params].hideResultsTitle ?
                                                        <>
                                                            {props.onLoadRun ?
                                                                <div><small id="reportNameRef">{repName}</small></div> :
                                                                <h3 id="reportNameRef" className='type-heading-h3-primary'>{repName}</h3>
                                                            }
                                                            <div className="type-caption-on-light pb-3">{user} {FDS.dateToText(new Date(), "/", true, false)}</div>
                                                        </> :
                                                        <div id="reportNameRef" className='m-0 p-0' style={{ width: 0, height: 0, overflow: 'hidden' }}>{repName}</div>
                                                }
                                            </Col>

                                            <TitlePanel parentProps={props} reportIpRef={reportIpRef} expandResults={expandResults} showReport={showReport} tab={tab} repName={repName} />

                                            <>
                                                {props.onLoadRun || templates[props.params].hasOwnProperty("chartsLists") ?
                                                    <div className='filterListWrap mt-3 mb-3'>
                                                        {{ ...templates[props.params] }?.lists.map((list: any, q: number) => {
                                                            return list.wraps.map((wrap: any, i: number) => {
                                                                return (
                                                                    <React.Fragment key={i}>
                                                                        {wrap.fields.map((field: any, j: number) => {
                                                                            return (
                                                                                <React.Fragment key={j}>
                                                                                    {
                                                                                        reportIpRef.template[field.name]?.length > 0 || (typeof reportIpRef.template[field.name] === "object" && !Array.isArray(reportIpRef.template[field.name])) ? (
                                                                                            <span className="fds-tag info ms-0 mb-1">
                                                                                                {field.hLabel || field.label}:
                                                                                                <b className='ps-2'>
                                                                                                    {
                                                                                                        typeof reportIpRef.template[field.name] === "object" && !Array.isArray(reportIpRef.template[field.name]) ?
                                                                                                            Object.keys(reportIpRef.template[field.name])
                                                                                                                .sort((a: any, b: any) => a < b ? 1 : -1)
                                                                                                                .map((item: any, k: number) => {
                                                                                                                    return (<span key={k}>
                                                                                                                        {reportIpRef.template[field.name][item] ?
                                                                                                                            <React.Fragment>
                                                                                                                                {!field.startDate ? <span style={{ textTransform: 'capitalize' }}>{item}</span> : null}
                                                                                                                                {field.startDate && item === "startDate" ?
                                                                                                                                    reportIpRef.template[field.name]["startDate"][0] + " " + (reportIpRef.template[field.name]["endDate"][0] ? FDS.valueOrDash(null) + " " + reportIpRef.template[field.name]["endDate"][0] : "") +
                                                                                                                                    (reportIpRef.template[field.name]["startDate"].length > 1 ? " vs " + reportIpRef.template[field.name]["startDate"][1] + " " + (reportIpRef.template[field.name]["endDate"][1] ? FDS.valueOrDash(null) + " " + reportIpRef.template[field.name]["endDate"][1] : "") : "") :
                                                                                                                                    null}
                                                                                                                                {!field.startDate ? `[${reportIpRef.template[field.name][item]?.toString()}]   &nbsp;` : null}
                                                                                                                            </React.Fragment>
                                                                                                                            : null
                                                                                                                        }
                                                                                                                    </span>)
                                                                                                                }) :
                                                                                                            Array.isArray(reportIpRef.template[field.name]) ?
                                                                                                                <span>
                                                                                                                    {
                                                                                                                        reportIpRef.template[field.name].map((item: string, i: number) => {
                                                                                                                            return field.options.filter((opt: any) =>
                                                                                                                                opt[field.codeKey] === item)[0]?.[field.labelKey] || item
                                                                                                                        }).join(", ").toUpperCase()
                                                                                                                    }
                                                                                                                </span> :
                                                                                                                <span>
                                                                                                                    {
                                                                                                                        (field.type === "select" ?
                                                                                                                            field.options.filter((opt: any) => opt['key']?.toLowerCase() === reportIpRef.template[field.name].toLowerCase())[0]?.['value'] :
                                                                                                                            reportIpRef.template[field.name]?.toString().replace(/,/gi, ', ')).toUpperCase()
                                                                                                                    }
                                                                                                                </span>
                                                                                                    }
                                                                                                </b>
                                                                                            </span>
                                                                                        ) : null
                                                                                    }
                                                                                    {
                                                                                        field.type === "toggleCheckbox" ?
                                                                                            field.options.map((item: any, k: number) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        {
                                                                                                            reportIpRef.template[item.key] ?
                                                                                                                <span className="fds-tag info ms-0 mb-1">
                                                                                                                    {item.value}:
                                                                                                                    <b>{reportIpRef.template[item.key] ?
                                                                                                                        (<i className="fds-glyphs-checkbox-checked2 infoTxt" />) :
                                                                                                                        (<i className="fds-glyphs-cancel-square2 dangerTxt" />)
                                                                                                                    }</b>
                                                                                                                </span> :
                                                                                                                null
                                                                                                        }
                                                                                                    </>
                                                                                                )
                                                                                            }) : null
                                                                                    }
                                                                                </React.Fragment>
                                                                            )
                                                                        })}
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        })}
                                                    </div> : null
                                                }
                                            </>
                                        </> :
                                        null
                                    }
                                </Row>
                                <>
                                    {templates[props.params].lists.length > 1 ? <div className="backMenu">
                                        <ul>
                                            {[...templates[props.params].lists].map((list: any, i: number) => {
                                                if (!list.showFor || customersRef?.customer?.data_content?.indexOf(list.showFor.subscription) >= 0) {
                                                    return (<li key={i} onClick={() => {
                                                        window.document.querySelectorAll(".minBtn")[0]?.click();
                                                        window.document.querySelectorAll(".arrowList .list-group-item")[i]?.click();
                                                    }}><span className={`${['', 'success', 'info', 'warning'][i]}Txt`}><i className='fds-glyphs-pencil3'></i></span> {list.label}</li>)
                                                }
                                                return null
                                            })}
                                        </ul>
                                    </div> : null}
                                </>
                                {showResults()}
                            </div> :
                            <div className='resultsLoading pt-3'>
                                {props.onLoadRun ?
                                    <>
                                        {showResults()}
                                    </> :
                                    null
                                }
                            </div>
                        }
                    </Col> :
                    <Col id="emptyResultWrap">
                        <i className='icon mb-3 fds-glyphs-airplane' />
                        <div><b>Narrow Down Your Results Using The Filters</b></div>
                    </Col>
                }
            </>
            <CheckReportReady reportType={props.params} onReady={(resp: any) => reportReady(resp.body, resp.reportType, resp.statusCode)} />
            {
                showLongRunning ?
                    <Modal className='longRunningPopup' show={showLongRunning} onHide={() => { setShowLongRunning(false); }} style={{ zIndex: '10000' }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Long Running Request</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ width: '100%' }}>
                            {longRunMsg}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => { setShowLongRunning(false); time.current = 0; }}>
                                Wait till report generates
                            </Button>
                            <Button variant="secondary" onClick={() => {
                                setShowLongRunning(false);
                                localStorage.removeItem("reportRequestId");
                                window.document.querySelectorAll(".minBtn")[0]?.click();
                                //window.location.href = "/#/";
                                dispatch({ type: "LOADING", data: "" });
                            }}>
                                Run in background
                            </Button>
                        </Modal.Footer>
                    </Modal> : null
            }
        </React.Fragment >
    );
};