import React, { useState, useEffect, useCallback } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
// import { Security, LoginCallback, SecureRoute } from '@okta/okta-react'; //SecureRoute
// import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import Login from '../../okta/Login';
import Home from '../home';
import { Header } from './Header';
import { Admin, CustomerInfo, CustomerDetails, Profile } from '../admin';
import { Messages } from "../messages";
// import { oktaAuthConfig, oktaSignInConfig } from '../../okta/config';
import { useAuth0 } from '@auth0/auth0-react';
import Reports from '../reports/Reports';
import { Groupings } from '../groupings/Groupings';
import { HubHistory, HubTemplates } from '../hub';
import { ReferenceData } from '../referenceData';

import * as FDS from '@arctravel/react-fds/lib';
import StatusToast from '../toasts';
import { Loader } from '../utils/Loader';
import { clearLocalStorage, Const } from '../utils';
import { getOts, getOtsLength, isInternalAdmin, isInternalAdminOrUser } from '../../okta/ls';

import { useDispatch, useSelector } from 'react-redux';
import { setLoginAction, getLoginAction, getNotificationsAction, getCustomersAction, downloadReport } from '../../store/actions';
import ViewAsHeader from '../viewAs/ViewAsHeader';

import { datadogRum } from '@datadog/browser-rum';

declare const window: any;

const INACTIVE_TIMEOUT_THRESHOLD_IN_MIN = 15;
const TOKEN_REFRESH_THRESHOLD_IN_MIN = 1;

const Logout = () => {
    const { loginWithRedirect } = useAuth0()
    return (
        <div style={{ margin: 'auto', width: "50%", padding: "10% 0", textAlign: "center" }}>
            <h1>
                You have been logged out from Discovery Point. <br />
                You may log back in using the button below.
            </h1>
            <div className='pt-3 mt-3'>
                <Button variant='primary' onClick={() => {
                    loginWithRedirect()
                    clearLocalStorage();
                    // window.location.href = "/";
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 500);

                }}>Login Again</Button>
            </div>
        </div>
    )
}

const AppRouting = () => {
    const customersRef: any = useSelector((state: any) => state.customersRef);
    const [cust, setCust] = useState(customersRef.customer);

    useEffect(() => {
        setCust(customersRef.customer);
    }, [customersRef.customer]);

    const Empty = () => {
        return (
            <div style={{ textAlign: "center", paddingTop: "6rem" }}>
                <h5>Access Not Permitted or Page Not Available</h5>
            </div>
        )
    }

    return (
        <Switch>
            <Route key={1} exact path="/">
                <Redirect to="/home" />
            </Route>
            <Route key={2} exact path='/home' component={Home} />
            <Route key={3} exact path='/reports/:id' component={Reports} />
            {cust?.modules?.indexOf("CUSTOM GROUPINGS") >= 0 && <Route key={4} exact path="/groupings/:id" component={Groupings} />}
            {cust?.modules?.indexOf("REPORTING") >= 0 && <Route key={5} exact path='/hub/:id' component={HubHistory} />}
            {cust?.modules?.indexOf("REPORTING") >= 0 && <Route key={6} exact path='/hubTemplates' component={HubTemplates} />}
            {cust?.modules?.indexOf("REFERENCE DATA") >= 0 && <Route key={7} exact path='/ref/:id' component={ReferenceData} />}
            {(isInternalAdmin() && (customersRef.viewAs.role === Const.INTERNAL_ADMIN || customersRef.viewAs.role === Const.EXTERNAL_ADMIN)) || getOts()?.idToken?.claims?.role.indexOf(Const.EXTERNAL_ADMIN) >= 0 ?
                [
                    <Route key={9} exact path='/admin' component={Admin} />,
                    <Route key={10} exact path='/admin/customerinfo' component={CustomerInfo} />,
                    <Route key={11} exact path='/admin/customer-details/:id' component={CustomerDetails} />,
                    isInternalAdmin() && customersRef.viewAs.role === Const.INTERNAL_ADMIN && <Route key={12} exact path='/admin/messages' component={Messages} />
                ] : null
            }
            <Route key={8} exact path='/profile' component={Profile} />
            <Route path='/login' render={() => <Login />} />
            {/* <Route path='/login/callback' component={LoginCallback} /> */}
            <Route path='/logout' component={Logout} />
            <Route path='/:id' component={Empty} />
        </Switch>
    )
}

const EmailDownload = (props: any) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.repId?.length > 0) {
            setTimeout(() => {
                dispatch(downloadReport(props.repId));
            }, 1500);
        }
    }, [props.repId, dispatch]);

    return (
        <React.Fragment>
            <>
                <FDS.FDSHeader config={{
                    productname: "Discovery Point",
                    username: props.user?.name || "",
                    links: [],
                    helpLink: "",
                    helpTarget: "_blank",
                    showClose: false,
                    closeLabel: "",
                    notifications: []
                }} onClose={() => { }} />
                <div className="text-center pt-3 pb-3 mb-3" style={{ marginTop: 'calc(50vh - 300px)' }}>
                    <h1 className='pt-3 mt-3 pb-3'>{props.repId}</h1>
                    <div className='pb-3'>
                        File downloads automatically, if not, check <b>Popup-Blocker</b>.
                    </div>
                    <h6 className='pb-3 mb-3'><small>You can now close the window or continue to <b>Discovery Point</b> application.</small></h6>
                    <Button className='me-2' variant='secondary' onClick={() => {
                        localStorage.removeItem("report_id");
                        localStorage.removeItem("skip_report_id");
                        window.close();
                    }}>Close</Button>
                    <Button className='ms-2' variant='primary' onClick={() => {
                        localStorage.removeItem("report_id");
                        localStorage.removeItem("skip_report_id");
                        window.location = "/";
                    }}>Continue to Discovery Point</Button>
                </div>
                <div className='pt-3 mt-3' style={{ position: 'fixed', bottom: 0, width: '100%' }}>
                    <FDS.FDSFooter />
                </div>
            </>
        </React.Fragment>
    )
}

const App = () => {
    const dispatch = useDispatch();
    const { isAuthenticated, logout, getIdTokenClaims, loginWithRedirect, isLoading, error, user } = useAuth0();
    // const newOktaAuth = new OktaAuth(oktaAuthConfig);    
    // const [user, setUser] = useState(getOtsLength() > 0 ? getOts()?.idToken?.claims?.name : "");
    // const [logger, setLogger] = useState(false);
    const [repId, setRepId] = useState(localStorage.getItem('report_id') || "");
    const [isLogged, setIsLogged] = useState(false);
    const [productName, setProductName] = useState("");
    const { search } = useLocation();
    const history = useHistory();

    const customersRef: any = useSelector((state: any) => state.customersRef);
    const [cust, setCust] = useState(customersRef.customer);

    useEffect(() => {
        setCust(customersRef.customer);
    }, [customersRef.customer]);


    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        const action = searchParams.get("action");
        const report_id = searchParams.get("report");
        if (action === "download" && report_id) {
            localStorage.setItem("report_id", report_id);
            setRepId(localStorage.getItem('report_id') || "");
        }
    }, [search]);

    useEffect(() => {
        // For Themes
        if (history.location.pathname?.indexOf(Const.ARCCI) >= 0 || (cust.modules.indexOf(Const.ARCCI) >= 0 && cust.org !== Const.ARC_ROLE)) {
            //setProductName(Const.ARCCI_ProductName);
            //document.title = "ARC " + Const.ARCCI_ProductName;
            document.body.classList.add("night");
        } else {
            setProductName(Const.ProductName);
            document.title = Const.ProductName;
            document.body.classList.remove("night");
        }
    }, [history.location.pathname, cust]);

    // authO
    const logoutGracefully = useCallback(() => {
        dispatch(getNotificationsAction(false));
        clearLocalStorage();
        setIsLogged(false)
        // setLogger(true)
        // authO Change
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            }
        });
    }, [dispatch, logout])

    useEffect(() => {
        if (repId?.length <= 0 && isLogged) {
            dispatch({ type: "SET_VIEW_AS", data: isInternalAdminOrUser() ? { ...Const.DEFAULT_VIEW_AS } : {} });
            dispatch(getCustomersAction(true));
            // dispatch(getCustomerAction(getOts()?.idToken?.orgName || getOts()?.accessToken?.claims?.org, true));
        }
    }, [dispatch, repId, isLogged]);

    useEffect(() => {
        setTimeout(async () => {
            try {
                var token: any = await getIdTokenClaims();
                if (token != null && user) {
                    dispatch({ type: "LOGIN", data: dispatch(setLoginAction(token)) });
                    setIsLogged(true)
                }
            } catch (e) { logoutGracefully() }
        }, 500);
    }, [dispatch, logoutGracefully, getIdTokenClaims, user]);

    // Inactivity case
    useEffect(() => {
        let t: any;

        // FullStory identification
        if (window.FS && getOtsLength() > 0) {
            window.FS.identify(getOts()?.idToken?.claims?.sub, {
                displayName: getOts()?.idToken?.claims?.name,
                email: getOts()?.idToken?.claims?.email
            });
        }
        t = setInterval(async () => {
            if (localStorage.getItem("expiresAt")) {
                // const diff1hr = (Math.abs(Number(localStorage.getItem("expiresAt") + "") * 1000 - new Date().getTime()) / 1000 / 60)
                const tokenExp = localStorage.getItem("expiresAt")
                const lastActive = localStorage.getItem("lastActive")
                const diff1hr = (+new Date(parseInt(tokenExp + "") * 1000) - +new Date()) / 1000 / 60;
                const diff15min = (+new Date() - +new Date(lastActive + "")) / 1000 / 60;

                if (["DEV", "QA"].indexOf(window.ENV.type) >= 0) {
                    console.log("DIFFFFFFFFFFFFFFFFFFF:", diff15min.toFixed(2), diff1hr.toFixed(2));
                }

                if (diff15min >= (INACTIVE_TIMEOUT_THRESHOLD_IN_MIN - 2) && diff15min < INACTIVE_TIMEOUT_THRESHOLD_IN_MIN && localStorage.getItem("isTimeoutShowed") !== "true") {
                    dispatch({ type: "STATUS", data: { type: "WARNING", title: "About to Timeout", message: "You have been inactive for a while, and the session will expire soon unless there is no further action." } });
                    localStorage.setItem("isTimeoutShowed", "true");
                } else if (diff15min >= INACTIVE_TIMEOUT_THRESHOLD_IN_MIN) {
                    dispatch({ type: "STATUS", data: { type: "DANGER", title: "Timeout", message: "Your session has ended. Logging out now." } });
                    clearInterval(t);
                    setTimeout(() => {
                        logoutGracefully();
                    }, 5000);
                } else if (diff1hr > 0 && diff1hr < TOKEN_REFRESH_THRESHOLD_IN_MIN && localStorage.getItem("isTimeoutShowed") !== "true") {
                    //Your 1 hour session is about to expire.
                    console.log("Token about to timed out. Retrieveing a new token");
                    localStorage.setItem("isRefreshCalled", "true");
                    dispatch(getLoginAction());
                } else if (diff1hr < 0 || new Date(parseInt(tokenExp + "") * 1000) < new Date()) {
                    dispatch({ type: "LOADING", data: "" });
                    dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `Login Required` } });
                    setTimeout(() => {
                        logoutGracefully();
                    }, 2000);
                }
            }
        }, 10000);
    }, [dispatch, logoutGracefully])

    // Handle login failure and send RUM event
    useEffect(() => {
        if (error) {
            // Send a custom RUM event for login failure
            if (datadogRum) {
                datadogRum.addAction('ADH Login Failure', {
                    message: 'Failed to Login. Try again',
                    error: true,
                    errorDetails: error.message || 'Unknown error',
                    type: 'error'
                });
            }
        }
    }, [error]);

    if (isLoading) {
        return (
            <div className='authLoader'>
                <Spinner animation="border" role="status" variant="primary">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        )
    }

    if (error) {
        return (
            <div style={{ margin: 'auto', width: "50%", padding: "10% 0", textAlign: "center" }}>
                <h1>
                    Failed to Login. Try again !
                </h1>
                <div className='pt-3 mt-3'>
                    <Button variant='primary' onClick={() => loginWithRedirect()}>Login Again</Button>
                </div>
            </div>
        )
    }

    if (isAuthenticated && !isLoading && !error && user) {
        return <React.Fragment>
            {
                repId?.length <= 0 ?
                    <React.Fragment>
                        <div style={{ minHeight: "calc(100vh - 131px)" }}>
                            {isInternalAdminOrUser() && <ViewAsHeader />}
                            <Header productName={productName} user={user.name} logout={logoutGracefully} />
                            <AppRouting />
                        </div>
                        <FDS.FDSFooter />
                        <StatusToast />
                        <Loader />
                    </React.Fragment > :
                    <React.Fragment>
                        <EmailDownload user={user.name} repId={repId} />
                        <StatusToast />
                        <Loader />
                    </React.Fragment>
            }
        </React.Fragment>

    } else {
        return <Login />
    }

    // return (
    //     <React.Fragment>
    //         {
    //             repId?.length <= 0 ?
    //                 <React.Fragment>
    //                     <div style={{ minHeight: "calc(100vh - 131px)" }}>
    //                         {user?.length > 0 && isInternalAdminOrUser() && <ViewAsHeader />}
    //                         {user?.length > 0 ? <Header user={user} logout={logoutGracefully} /> : null}
    //                         <AppRouting setUser={setUser} setDownload={setDownload} />
    //                     </div>
    //                     {user ? <FDS.FDSFooter /> : null}
    //                     <StatusToast />
    //                     <Loader />
    //                 </React.Fragment > :
    //                 <React.Fragment>
    //                     {user?.length > 0 ?
    //                         <>
    //                             <FDS.FDSHeader config={{
    //                                 productname: "Discovery Point",
    //                                 username: user,
    //                                 links: [],
    //                                 helpLink: "",
    //                                 helpTarget: "_blank",
    //                                 showClose: false,
    //                                 closeLabel: "",
    //                                 notifications: []
    //                             }} onClose={() => { }} />
    //                             <div className="text-center pt-3 pb-3 mb-3">
    //                                 <h1 className='pt-3 mt-3 pb-3'>{repId}</h1>
    //                                 <div className='pb-3'>
    //                                     File downloads automatically, if not, check <b>Popup-Blocker</b> or click
    //                                     <a href="/" className='ps-1 pe-1' onClick={(ev) => {
    //                                         ev.preventDefault();
    //                                         window.location = '/#/hub/' + repId.substring(0, repId.indexOf(".xls"));
    //                                         window.location.reload();
    //                                     }}><b>here</b></a>
    //                                     to download it manually from history.
    //                                 </div>
    //                                 <h6 className='pb-3 mb-3'><small>You can now close the window or continue to <b>Discovery Point</b> application.</small></h6>
    //                                 <Button className='me-2' variant='secondary' onClick={() => {
    //                                     localStorage.removeItem("report_id");
    //                                     window.close();
    //                                 }}>Close</Button>
    //                                 <Button className='ms-2' variant='primary' onClick={() => {
    //                                     localStorage.removeItem("report_id");
    //                                     window.location = "/";
    //                                 }}>Continue to Discovery Point</Button>
    //                             </div>
    //                             <div className='pt-3 mt-3'>
    //                                 <FDS.FDSFooter />
    //                             </div>
    //                         </> :
    //                         <Logout />
    //                     }
    //                 </React.Fragment>
    //         }
    //     </React.Fragment>
    // );
};

export default App;